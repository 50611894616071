
import { ElForm } from 'element-ui/types/form';
import { Component, Vue } from 'vue-property-decorator';
import { getUserCouponList } from '@/api/userCoupon';
import { registerCoupon } from '@/api/coupon';

@Component({})
export default class extends Vue {
  mounted() {
    this.getCouponList();
  }

  private goBack() {
    this.$router.go(-1);
  }

  private loading = true;

  private couponList = [];

  private couponForm = {
    code: '',
    paperStatus: true,
  }

  private rules = {
    code: [
      { required: true, message: '쿠폰 코드를 입력해주세요.', trigger: 'blur' },
      { min: 12, message: '쿠폰 코드를 12자로 입력해주세요.', trigger: 'blur' },
    ],
  }

  private handleRegisterCoupon() {
    (this.$refs.couponForm as ElForm).validate((valid: boolean) => {
      if (valid) {
        registerCoupon(this.couponForm).then(() => {
          this.$message.success('쿠폰이 등록되었습니다.');
          this.$router.push({ name: 'couponIndex' });
        }).catch((error) => {
          let message = '쿠폰등록에 실패했습니다.';
          if (error.response.status === 400 || error.response.status === 404) {
            if (error.response.data.message) message = error.response.data.message;
          }
          this.$message.error(message);
        });
      }
    });
  }

  private getCouponList() {
    this.loading = true;
    getUserCouponList(null).then((res) => {
      this.loading = false;
      this.couponList = res.data;
    });
  }
}
